import React from 'react'
import { NumericFormat } from 'react-number-format';
import { maskNumberBr, maskPrice } from '../../../Auxiliar/Masks';

const NewMaterial = (props) => {
  const [warning, setWarning] = React.useState(true)
  return (
    <>
      {props.state.Materialselecionado[0].unidade_principal.tipo == 'Peso' &&
        <div className="row">
          <div className="col-md-6 col-12">
            <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon3">Disponível: 0{props.state.Materialselecionado[0].unidade_principal.sigla}</span>
              <NumericFormat className="form-control quantidade" id="peso"
                placeholder="Informe o peso" type="text"
                thousandsGroupStyle='thousand'
                thousandSeparator="."
                decimalScale={2}
                decimalSeparator=","
                valueIsNumericString
                value={props.state.Peso} onValueChange={(values, e) => {
                  let mult;
                  values.value == '' ? mult = 0.00 : mult = values.value;
                  let val_total = parseFloat(props.state.Materialselecionado[0].valor_saldo_financeiro).toFixed(2) * parseFloat(mult);
                  props.setState({
                    required: false,
                    validated: false,
                    UnidadePeso: props.state.Materialselecionado[0].unidade_principal.sigla,
                    UnidadeVolume: props.state.Materialselecionado[0].unidade_secundaria.sigla,
                    Peso_kg: parseFloat(values.value) * parseFloat(props.state.Materialselecionado[0].unidade_principal.equivalencia),
                    Volume_lt: (parseFloat(values.value) * parseFloat(props.state.Materialselecionado[0].equivalencia)) * parseFloat(props.state.Materialselecionado[0].unidade_secundaria.equivalencia),
                    Peso: values.value,
                    ValorFinanceiro: parseFloat(val_total).toFixed(2),
                    Volume: values.value == '' ? '' : parseFloat(values.value) * parseFloat(props.state.Materialselecionado[0].equivalencia)
                  })
                  setWarning(false)
                }} />
              {warning && <b style={{ color: 'red', fontSize: '12px' }}>O material selecionado não possui entradas, ao salvar serão criados registros</b>}
            </div>
            {props.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Peso é obrigatório</b>}
            {props.state.validated == true && <b style={{ color: 'red', fontSize: '12px' }}>O Volume atendido não pode ser maior que o disponivel</b>}
          </div>
          <div className="col-md-6 col-12">
            <label className="col-sm-12 col-form-label">Volume<b style={{ color: 'red' }}>*</b></label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon3">Disponível: 0{props.state.Materialselecionado[0].unidade_secundaria.sigla}</span>
              <NumericFormat className="form-control" id="volume"
                disabled placeholder="Informe o volume" type="text"
                thousandsGroupStyle='thousand'
                thousandSeparator="."
                decimalScale={2}
                decimalSeparator=","
                valueIsNumericString
                value={props.state.Volume} />
            </div>
          </div>
        </div>}

      {props.state.Materialselecionado[0].unidade_principal.tipo !== 'Peso' &&
        <div className="row">
          <div className="col-md-6 col-12">
            <label className="col-sm-12 col-form-label">{this.state.Materialselecionado[0].unidade_principal.tipo}<b style={{ color: 'red' }}>*</b></label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon3">Disponível: 0{props.state.Materialselecionado[0].unidade_principal.sigla}</span>
              <NumericFormat type='text' className="form-control quantidade" id='volume'
                thousandsGroupStyle='thousand'
                thousandSeparator="."
                decimalScale={2}
                decimalSeparator=","
                valueIsNumericString
                placeholder={`${this.state.Materialselecionado[0].unidade_principal.tipo === 'Volume' ? 'Informe o' : 'Informe a'} ${this.state.Materialselecionado[0].unidade_principal.tipo}`}
                onValueChange={(values, e) => {
                  let mult;
                  values.value == '' ? mult = 0.00 : mult = values.value;
                  let val_total = parseFloat(props.state.Materialselecionado[0].valor_saldo_financeiro).toFixed(2) * parseFloat(mult)
                  props.setState({
                    required: false,
                    validated: false,
                    UnidadePeso: props.state.Materialselecionado[0].unidade_secundaria.sigla,
                    UnidadeVolume: props.state.Materialselecionado[0].unidade_principal.sigla,
                    Volume_lt: parseFloat(values.value) * parseFloat(props.state.Materialselecionado[0].unidade_principal.equivalencia),
                    Peso_kg: (parseFloat(values.value) * parseFloat(props.state.Materialselecionado[0].equivalencia)) * parseFloat(props.state.Materialselecionado[0].unidade_secundaria.equivalencia),
                    Volume: values.value,
                    ValorFinanceiro: parseFloat(val_total).toFixed(2),
                    Peso: values.value == '' ? '' : parseFloat(values.value) * parseFloat(props.state.Materialselecionado[0].equivalencia)
                  })
                }}
                value={props.state.Volume} />
            </div>
            {props.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Volume é obrigatório</b>}
            {props.state.validated == true && <b style={{ color: 'red', fontSize: '12px' }}>O Volume atendido não pode ser maior que o disponivel</b>}
          </div>
          <div className="col-md-6 col-12">
            <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon3">Disponível: 0{props.state.Materialselecionado[0].unidade_secundaria.sigla}</span>
              <NumericFormat type='text' className="form-control" id='peso'
                disabled
                valueIsNumericString
                thousandsGroupStyle='thousand'
                thousandSeparator="."
                decimalScale={2}
                decimalSeparator=","
                placeholder="Informe o peso"
                onValueChange={(values, sourceInfo) => {
                  props.setState({ Peso: values.value })
                }}
                value={props.state.Peso} />
            </div>
          </div>
        </div>}

      <div className="row">
        <div className="col-md-6 col-12">
          <label className="col-sm-12 col-form-label">Tipo de movimentação<b style={{ color: 'red' }}>*</b></label>
          <div className="input-group mb-3">
            <select value={props.state.Movimentacao} className="form-control"
              onChange={(e) => {
                if (e.target.value == 'Outros') {
                  props.setState({
                    Outros: true,
                    Movimentacao: 'Não Aplicável'
                  })
                } else {
                  props.setState({
                    Movimentacao: e.target.value,
                    Outros: false,
                  })
                }
              }}>
              <option value={'Outros'}>Outros</option>
              <option value={'Receita'}>Receita</option>
              <option value={'Despesa'}>Despesa</option>

            </select>
          </div>
        </div>
        {props.state.Outros == true &&
          <div className="col-md-6 col-12">
            <label className="col-sm-12 col-form-label">Outros<b style={{ color: 'red' }}>*</b></label>
            <div className="input-group mb-3">
              <select value={props.state.Movimentacao} className="form-control"
                onChange={(e) => {
                  props.setState({
                    Movimentacao: e.target.value
                  })
                }}>
                <option value={'Não Aplicável'}>Não Aplicável</option>
                <option value={'Doação'}>Doação</option>
                <option value={'Despesa Evitada'}>Despesa Evitada</option>
              </select>
            </div>
          </div>}
        {props.state.Outros == false && <div className="col-md-6 col-12">
          <label className="col-sm-12 col-form-label">Valor financeiro<b style={{ color: 'red' }}>*</b></label>
          <div className="input-group mb-3">
            <NumericFormat className="form-control"
              placeholder="R$ 99,99" type="text"
              prefix={'R$ '}
              thousandsGroupStyle='thousand'
              thousandSeparator="."
              decimalScale={2}
              decimalSeparator=","
              valueIsNumericString
              onValueChange={(values, e) => {
                props.setState({ ValorFinanceiro: values.value })
              }}
              value={props.state.ValorFinanceiro} />
            {/* <input className="form-control" placeholder="R$ 99,99" type="text" value={maskPrice(props.state.ValorFinanceiro)} onChange={(e) => console.log((e.target.value))} /> */}
          </div>
        </div>}
        {props.state.Outros == true && <div className="col-md-6 col-12">
          <label className="col-sm-12 col-form-label">Valor financeiro<b style={{ color: 'red' }}>*</b></label>
          <div className="input-group mb-3">
            <NumericFormat className="form-control" disabled placeholder="R$ 99,99" type="text"
              prefix={'R$ '}
              thousandsGroupStyle='thousand'
              thousandSeparator="."
              decimalScale={2}
              decimalSeparator=","
              valueIsNumericString
              value={maskPrice('0.00')}
              onValueChange={(values, e) => props.setState({ ValorFinanceiro: values.value })} />
          </div>
        </div>}
      </div>

      <div className="row">
        <div className="col-md-6 col-12">
          <label className="col-sm-12 col-form-label">Destinação<b style={{ color: 'red' }}>*</b></label>
          <div className="input-group mb-3">
            <select value={props.state.DestinacaoId} className="form-control"
              onChange={(e) => {
                props.setState({
                  DestinacaoId: e.target.value
                })
              }}>
              <option value={''}>selecione uma opção</option>
              {props.state.Destinacoes.map((item, id) => (<option key={id} value={item.id}>{'[' + item.codigo + '] ' + item.descricao}</option>))}
            </select>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <label className="col-sm-12 col-form-label">Tratamento<b style={{ color: 'red' }}>*</b></label>
          <div className="input-group mb-3">
            <select value={props.state.Tratamento} className="form-control"
              onChange={(e) => {
                props.setState({
                  Tratamento: e.target.value
                })
              }}>
              <option value={''}>selecione uma opção</option>
              {props.state.Tratamentos.map((item, id) => (<option key={id} value={item.id}>{'[' + item.codigo + '] ' + item.descricao}</option>))}
            </select>
          </div>
        </div>
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="button" className="btn btn-success" style={{ backgorundColor: '#f2f2f2' }} onClick={() => {
          if (props.state.Materialselecionado[0].unidade_principal.tipo === 'Peso' && props.state.Peso == 0 || props.state.Materialselecionado[0].unidade_principal.tipo === 'Peso' && props.state.Peso == '') {
            props.setState({
              required: true
            })
            document.getElementById('peso').focus();
          } else if (props.state.Materialselecionado[0].unidade_principal.tipo !== 'Peso' && props.state.Volume == 0 || props.state.Materialselecionado[0].unidade_principal.tipo !== 'Peso' && props.state.Volume == '') {
            props.setState({
              required: true
            })
            document.getElementById('volume').focus();
          }
          // else if (props.state.Materialselecionado[0].unidade_principal.tipo == 'Volume' && parseFloat(props.state.Volume) > parseFloat(props.state.EstoqueSelecionado[0].volume_disponivel)) {
          //     // props.setState({
          //     //     validated: true
          //     // })
          //     document.getElementById('volume').focus();
          // } else if (props.state.Materialselecionado[0].unidade_principal.tipo == 'Peso' && parseFloat(props.state.Peso) > parseFloat(props.state.EstoqueSelecionado[0].peso_disponivel)) {
          //     // props.setState({
          //     //     validated: true
          //     // })
          //     document.getElementById('peso').focus();
          // }
          else {
            let materiais = props.state.MateriaisInseridos;
            let novo_material = {
              "residuo_rejeito_id": props.state.Materialselecionado[0].id, "nome": props.state.Materialselecionado[0].nome, "unidade_peso": props.state.UnidadePeso,
              "unidade_volume": props.state.UnidadeVolume, "peso": props.state.Peso, "volume": props.state.Volume, "peso_kg": props.state.Peso_kg,
              "volume_lt": props.state.Volume_lt, "movimentacao": props.state.Movimentacao, "valor": props.state.ValorFinanceiro,
              "destinacao_disposicao_id": props.state.DestinacaoId, "tratamento_id": props.state.Tratamento
            };
            materiais.push(novo_material);

            let valor = props.state.ValorFinanceiro;
            console.log(props.state.MateriaisInseridos);
            let saldo;
            let receita;
            let despesa;
            console.log(parseFloat(valor));
            console.log(parseFloat(props.state.Saldo));
            console.log(parseFloat(props.state.ReceitaTotal));
            console.log(props.state.Movimentacao)
            if (props.state.Movimentacao == 'Receita') {
              saldo = parseFloat(parseFloat(props.state.Saldo).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
              receita = parseFloat(parseFloat(props.state.ReceitaTotal).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
              despesa = parseFloat(parseFloat(props.state.DespesaTotal).toFixed(2));
            } else if (props.state.Movimentacao == 'Despesa') {
              despesa = parseFloat(parseFloat(props.state.DespesaTotal).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
              saldo = parseFloat(parseFloat(props.state.Saldo).toFixed(2)) - parseFloat(parseFloat(valor).toFixed(2));
              receita = parseFloat(parseFloat(props.state.ReceitaTotal).toFixed(2));
            } else {
              despesa = parseFloat(parseFloat(props.state.DespesaTotal).toFixed(2));
              saldo = parseFloat(parseFloat(props.state.Saldo).toFixed(2));
              receita = parseFloat(parseFloat(props.state.ReceitaTotal).toFixed(2));
            }
            console.log(saldo);
            console.log(receita);
            console.log(despesa);
            props.setState({
              required: true,
              MateriaisInseridos: materiais,
              Materialselecionado: {},
              MaterialId: '',
              Volume: '',
              Peso: '',
              Movimentacao: '',
              Outros: false,
              ValorFinanceiro: '',
              DestinacaoId: '',
              Tratamento: '',
              PesoTotal: parseFloat(props.state.PesoTotal) + parseFloat(props.state.Peso_kg),
              VolumeTotal: parseFloat(props.state.VolumeTotal) + parseFloat(props.state.Volume_lt),
              ReceitaTotal: receita.toFixed(2).toString(),
              DespesaTotal: despesa.toFixed(2).toString(),
              Saldo: saldo.toFixed(2).toString(),
            })
          }
        }
        }>Inserir</button>
      </div>
    </>
  )
}

export default NewMaterial